import { readCookie } from "./readCookie";

const { tradedeskConfig } = window;
const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

/**
 * Validates what action we have to take for Tradedesk
 * Could be:
 *  - none
 *  - get
 *  - refresh
 */
export function checkTradedesk() {
  // eslint-disable-next-line eqeqeq
  if (window.adConfig.userId == 0) {
    return {
      action: "none",
    } as const;
  }
  // Initializes first event
  if (typeof window.adsJSON.events === "undefined") {
    window.adsJSON.events = ["uidUserLoggedIn"];
  } else {
    window.adsJSON.events.push("uidUserLoggedIn");
  }
  window.dispatchEvent(new CustomEvent("uidUserLoggedIn"));
  const tradedeskCookie = readCookie(tradedeskConfig.tradedeskCookieName);
  const tradedeskOptOutCookie = readCookie(
    `${tradedeskConfig.tradedeskCookieName}optout`
  );

  // check if cookie exists or opt out cookie exists
  if (tradedeskCookie || tradedeskOptOutCookie) {
    if (tradedeskOptOutCookie) {
      window.adsJSON.events.push("uidCookieOptedOut");
      window.dispatchEvent(new CustomEvent("uidCookieOptedOut"));
    } else {
      const tradedeskCookieDecoded = JSON.parse(tradedeskCookie);

      // if we are past cookie duration or we don't have a refresh_from value, we should get a new token
      if (
        tradedeskCookieDecoded.refresh_expires < Date.now() ||
        tradedeskCookieDecoded.refresh_from == null
      ) {
        window.adsJSON.events.push("uidCookiePresentAndExpired");
        window.dispatchEvent(new CustomEvent("uidCookiePresentAndExpired"));
        return {
          action: "get",
        } as const;
        // if we are not past expiration then do nothing and let prebid module handle
      }
      window.adsJSON.events.push("uidCookiePresentAndNotExpired");
      window.dispatchEvent(new CustomEvent("uidCookiePresentAndNotExpired"));
    }
    return {
      action: "none",
    } as const;
  }
  window.adsJSON.events.push("uidCookieNotPresent");
  window.dispatchEvent(new CustomEvent("uidCookieNotPresent"));
  return {
    action: "get",
  } as const;
}

/**
 * Takes the BE response, and decodes the Epsilon data, storing it in applicable cookies
 *
 * @param response Our response from the BE
 */
export function decodeTradedesk(response: unknown) {
  let value = {};
  if (response?.status === "optout") {
    // If user is opted out, only set the status
    value = {
      uidStatus: response?.status,
    };
    tradedeskConfig.tradedeskCookieName += "optout";
  } else {
    value = {
      advertising_token: response?.advertising_token,
      refresh_token: response?.refresh_token,
      identity_expires: response?.identity_expires,
      refresh_from: response?.refresh_from,
      refresh_expires: response?.refresh_expires,
      refresh_response_key: response?.refresh_response_key,
    };
  }

  const date = new Date();
  const expireTS =
    date.getTime() +
    tradedeskConfig.tradedeskCookieDuration * MILLISECONDS_PER_DAY; // Milliseconds
  date.setTime(expireTS);
  const expires = `; expires=${date.toGMTString()}`;
  window.adsJSON.events.push("uidCookieAboutToBeSet");
  window.dispatchEvent(new CustomEvent("uidCookieAboutToBeSet"));
  // create tradedesk cookies
  document.cookie = `${tradedeskConfig.tradedeskCookieName}=${JSON.stringify(
    value
  )}${expires}; path=/`;
  window.adsJSON.events.push("identitiesUpdated");
  window.dispatchEvent(new CustomEvent("identitiesUpdated"));
}
