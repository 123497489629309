const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

export function decodeGuestHash(response: string) {
  if (response) {
    vsCreateCookie("guest_hash", response);
    vsDeleteCookie("loggedin_hash");
  }
}

export function decodeLoggedInHash(response: string) {
  if (response) {
    vsCreateCookie("loggedin_hash", response);
    vsDeleteCookie("guest_hash");
  }
}

/**
 * Takes the BE response, and stores this as a cookie
 * @param name The (prefix) name of the cookie
 * @param value The value that will be set in the cookie
 * @param days The number of days before this cookie expires
 */
function vsCreateCookie(name: string, value: string) {
  const date = new Date();
  const days = 365;
  const expireTS = date.getTime() + days * MILLISECONDS_PER_DAY; // Milliseconds

  date.setTime(expireTS);
  const expires = `; expires=${date.toGMTString()}`;

  // create cookie
  document.cookie = `${name}=${value}${expires}; path=/`;
}

/**
 * Deletes unused cookie
 * @param name The (prefix) name of the cookie
 */
function vsDeleteCookie(name: string) {
  const date = new Date();

  date.setTime(date.getTime() - 86000);
  const expires = `; expires=${date.toGMTString()}`;

  // delete unused cookie
  document.cookie = `${name}=${expires}; path=/`;
}
